<template>
  <Modal ref="modal" title="Kandidaten voor een pand">
    <div>
      <p><strong>Kandidaten nog in aanmerking</strong> Dit zijn kandidaten die een actieve lead hebben voor dit pand.
      Dit zijn enkel leads die op de shortlist, longlist of coldlist staan.</p>
      <p><strong>Kandidaten nog niet gelinkt aan het pand</strong> Dit zijn kandidaten die een manuele HMODH voor dit
      pand hebben of een HMODH die nog niet verstuurd is geweest. Na het voorstellen van het pand zullen dit <em>
          kandidaten nog in aanmerking voor dit pand</em> worden.</p>
      <p><strong>Kandidaten die geen interesse meer hebben</strong> Dit zijn kandidaten die een lead hebben voor dit pand
      maar gebreaked zijn.</p>
    </div>
  </Modal>
</template>

<script>
import Modal from '../iam/Modal'

export default {
  name: 'CandidateHelpModal',
  components: { Modal },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="tw-border tw-rounded tw-p-2 tw-text-xs tw-w-auto">
    <router-link
      v-if="contact && contact.id"
      :to="{ name: 'ContactDetails', params: { id: contact.id } }"
      target="_blank"
      class="tw-text-sm tw-break-words tw-font-semibold"
    >
      {{ contact.display_name }}
    </router-link>

    <span
      :class="[
        'tw-px-1 tw-text-xs tw-rounded-sm tw-text-white',
        gdprStatus
      ]"
    >
      GDPR
    </span>

    <div class="tw-my-1">{{ getContactAddress(contact) }}</div>

    <div class="tw-flex tw-flex-col">
      <div v-if="contact.phone">
        <i class="fa fa-phone" />
        <a :href="`tel:${contact.phone}`">
          {{ contact.phone }}
        </a>
      </div>
      <div v-if="contact.mobile">
        <i class="fa fa-mobile" />
        <a :href="`tel:${contact.mobile}`">
          {{ contact.mobile }}
        </a>
      </div>
      <div v-if="contact.email">
        <i class="fa fa-envelope" />
        <Email type="to" :contact="contact" />
      </div>
      <div>
        <span v-if="contact.type === 'B'">{{ contact.vat }}</span>
        <ContactVatViesChecker
          v-if="contact.vat"
          :contact="contact"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Email from '@/components/iam/Email'
import ContactVatViesChecker from '@/components/contacts/ContactVatViesChecker'

import { getContactAddress } from '@/utils/helpers'

export default {
  name: 'EntityContact',
  components: {
    Email,
    ContactVatViesChecker
  },
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  computed: {
    gdprStatus () {
      if (this.contact.global_unsubscribe) {
        return 'tw-bg-danger'
      } else if (this.contact.consent_asked) {
        return 'tw-bg-primary'
      } else {
        return 'tw-bg-warning'
      }
    }
  },
  methods: {
    getContactAddress
  }
}
</script>

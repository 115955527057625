<template>
  <div>
    <BaseModal
      ref="modal"
      title="Geografische informatie"
      content-padding="tw-p-4"
      max-width="tw-max-w-[85%]"
      @hide="$emit('hide')"
    >
      <EntityMap
        ref="map"
        :latitude="latitude"
        :longitude="longitude"
        :parcelString="parcelString"
      />

      <div class="tw-float-right">
        <FormulateInput
          type="button"
          title="Opslaan"
          @click="showBuildingUnits"
        >
          <i class="fa tw-mr-1 fa-arrow-right" />
          Volgende
        </FormulateInput>
      </div>
    </BaseModal>

    <!-- We use the hack of forcing overflow: visible, to avoid the absolute select options of the multiselect from making the modal scroll. -->
    <BaseModal
      ref="buildingUnits"
      title="Gebouweenheden"
      content-padding="tw-p-4"
      max-width="!tw-overflow-visible tw-max-w-xl"
      @hide="focus"
    >
      <div v-if="progressBaseRegistersDataLoad !== 100">
        <div
          :title="`${Math.ceil(progressBaseRegistersDataLoad)}%`"
          class="tw-w-full tw-bg-gray-200 tw-rounded-full tw-h-2.5"
        >
          <div
            class="tw-bg-success tw-h-2.5 tw-rounded-full"
            :style="`width: ${progressBaseRegistersDataLoad}%`"
          />
        </div>
      </div>
      <FormulateForm
        v-else
        #default="{ isLoading }"
        v-model="baseRegistersData"
        name="baseRegistersData"
        @submit="save"
      >
        <FormulateInput
          type="group"
          name="parcels"
          debounce
          :repeatable="true"
          :error-class="['tw-text-right']"
          outer-class="tw-mb-1.5"
        >
          <template #default="{ index, model }">
            <transition name="fade" mode="out-in">
              <div
                v-if="model[index] && model[index].label"
                class="tw-flex tw-flex-row tw-gap-2 tw-justify-between tw-py-1.5 tw-items-center"
              >
                <div class="tw-w-1/3">
                  <label class="formulate-label">
                    Perceel
                  </label>
                  <span class="tw-block tw-break-words tw-leading-tight">
                    {{ model[index].label }}
                  </span>
                </div>
                <FormulateInput
                  v-if="model[index].building_units && model[index].building_units.length"
                  type="multiselect"
                  name="building_unit_ids"
                  placeholder="Selecteer gebouweenheden"
                  label="Gebouweenheden"
                  :options="model[index].building_units"
                  validation="bail|required|min:1,length"
                  :input-class="['tw-h-8 tw-text-sm']"
                  outer-class="tw-my-0 tw-flex-grow"
                />
                <p
                  v-else
                  class="tw-my-0 tw-flex-grow"
                >
                  Dit perceel heeft geen gebouweenheden
                </p>
              </div>
            </transition>
          </template>
          <template #addmore />
          <template #remove />
        </FormulateInput>

        <FormulateErrors class="tw-text-right" />
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          :outer-class="['tw-float-right']"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
      </FormulateForm>
    </BaseModal>
  </div>
</template>

<script>
import EntityMap from '@/components/properties/EntityMap'

import { mapActions } from 'vuex'
import { errorModal, successModal } from '@/modalMessages'

import { updateProperty, updatePropertyGeolocation } from '@/services/properties'
import { updateProjectDetails, updateProjectGeolocation } from '@/services/projects'
import {
  getParcelDetails,
  getBuildingUnits,
  getBuildingUnitDetails,
  getAddressDetails
} from '@/services/baseRegisters'

export default {
  name: 'EntityMapModal',
  components: { EntityMap },
  props: {
    entity: {
      type: Object
    },
    geolocation: {
      type: Object,
      default: () => {
        return {}
      }
    },
    parcelString: {
      type: String
    }
  },
  data () {
    return {
      baseRegistersData: {},
      buildingUnits: {},
      progressBaseRegistersDataLoad: 0
    }
  },
  computed: {
    latitude () {
      return this.geolocation.latitude
    },
    longitude () {
      return this.geolocation.longitude
    }
  },
  methods: {
    ...mapActions('properties', [
      'loadProperty',
      'loadProject'
    ]),

    show () {
      this.$refs.modal.show()
      this.$nextTick(() => {
        // Because the BaseModal uses a v-if to render the content, so we have to wait for the render to finish
        this.$refs.map.render()
      })
    },
    hide () {
      this.hideBuildingUnits()
      this.$nextTick(() => {
        // To prevent the modal being closed before it is focused, hence erroring out.
        this.$refs.modal.hide()
      })
    },
    focus () {
      if (!this.$refs.modal) return
      this.$refs.modal.focus()
    },
    hideBuildingUnits () {
      this.$refs.buildingUnits.hide()
    },
    async showBuildingUnits () {
      // Important for a parcel to be selected before fetching the building units
      const parcels = this.$refs.map.getParcels()
      if (!Object.keys(parcels).length) return errorModal('Er is geen perceel geselecteerd, probeer het opnieuw.')

      this.$refs.buildingUnits.show()
      const response = await this.fetchBuildingUnits()
      return response
    },
    async fetchBuildingUnits () {
      try {
        this.progressBaseRegistersDataLoad = 0
        let totalPromises = 0
        let promisesResolved = 0

        const parcels = this.$refs.map.getParcels()
        const baseRegistersData = {
          parcels: await Promise.all(Object.keys(parcels).map(
            async parcel => {
              totalPromises++
              // Since we are sending the capakey in the URL, we need to replace '/' with '-' (only for API call)
              const capakey = parcel.replace('/', '-')
              const response = await getParcelDetails(capakey)
              const addressIds = (response.data?.adressen || []).map(address => address.objectId)
              const storedParcel = this.entity.base_registers_data?.parcels?.find(({ capakey }) => capakey === parcel)
              const building_unit_ids = storedParcel?.building_unit_ids || []

              promisesResolved++
              this.progressBaseRegistersDataLoad = (promisesResolved / totalPromises) * 100

              return {
                capakey: parcel, // We send normal parcel (with '/') when saving to IAM
                building_unit_ids,
                label: parcels[parcel],
                building_units: (await Promise.all(
                  addressIds.map(
                    async adresObjectId => {
                      totalPromises++
                      const status = 'gerealiseerd' // only existing building units
                      const response = await getBuildingUnits({ adresObjectId, status })
                      promisesResolved++
                      this.progressBaseRegistersDataLoad = (promisesResolved / totalPromises) * 100

                      return (await Promise.all((response.data?.gebouweenheden || []).map(
                        async unit => {
                          totalPromises++
                          const unitId = unit.identificator.objectId
                          const response = await getBuildingUnitDetails(unitId)
                          promisesResolved++
                          this.progressBaseRegistersDataLoad = (promisesResolved / totalPromises) * 100

                          return {
                            label: `Gebouweenheid ${unitId}`,
                            children: await Promise.all((response.data?.adressen || []).map(
                              async address => {
                                totalPromises++
                                const response = await getAddressDetails(address.objectId)
                                const { straatnaam, huisnummer, busnummer } = response.data
                                const street = straatnaam.straatnaam.geografischeNaam.spelling
                                promisesResolved++
                                this.progressBaseRegistersDataLoad = (promisesResolved / totalPromises) * 100

                                return {
                                  value: unitId,
                                  label: `${street} ${huisnummer} ${busnummer || ''}`
                                }
                              }
                            ))
                          }
                        }
                      ))).flat()
                    }
                  )
                )).flat()
              }
            })
          )
        }
        this.baseRegistersData = baseRegistersData
        return baseRegistersData
      } catch (error) {
        this.hideBuildingUnits()
        errorModal('Kon niet alle wooneenheden laden, probeer het opnieuw met een kleiner aantal parceel.')
        console.log(error)
      } finally {
        this.$nextTick(() => {
          this.progressBaseRegistersDataLoad = 100
        })
      }
    },
    async save ({ parcels }) {
      try {
        const coords = this.$refs.map.getCoordinates()
        const geolocation = {
          latitude: coords.lat,
          longitude: coords.lon
        }
        const payload = {
          // We don't need to send rest of the info
          base_registers_data: {
            parcels: parcels.map(({ building_unit_ids, capakey }) => {
              return { capakey, building_unit_ids }
            })
          },
          parcels: Object.keys(this.$refs.map.getParcels()).join(',')
        }

        this.entity.entity_type === 'project'
          ? await Promise.all([
            updateProjectGeolocation(this.entity.id, geolocation),
            updateProjectDetails(this.entity.id, payload)
          ])
          : await Promise.all([
            updatePropertyGeolocation(this.entity.id, geolocation),
            updateProperty(this.entity.id, payload)
          ])

        const response = this.entity.entity_type === 'project'
          ? await this.loadProject(this.entity.id)
          : await this.loadProperty(this.entity.id)

        this.hide()
        successModal('Perceel succesvol opgeslagen')
        return response
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'baseRegistersData')
        errorModal('De locatie kon niet worden opgeslagen. Gelieve opnieuw te proberen.')
      }
    }
  }
}
</script>

<template>
  <div class="tw-inline">
    <button
      v-if="contact.vat"
      type="button"
      class="btn btn-default btn-xs tw-ml-1 tw-text-[8px]"
      @click="fetchViesDetails"
    >
      <i :class="[
        'fas',
        viesDataLoading ? 'fa-spinner-third fa-spin' : 'fa-search'
        ]"
      />
    </button>
    <BaseModal ref="viesModal" :title="contact.vat">
      <div v-if="viesData">
        <template v-if="viesData.valid">
          <span v-if="viesData.valid" class="tw-rounded tw-px-2 tw-bg-green-500 tw-text-white tw-mb-4 tw-inline-block">
            Geldig ondernemingsnummer
          </span>
          <p>Informatie volgens de Europese VIES-databank:</p>
          <span class="tw-italic">{{ viesData.name }}</span>
          <br />
          <span class="tw-italic">{{ viesData.address }}</span>
        </template>
        <template v-else>
          <span class="tw-rounded tw-px-2 tw-bg-red-500 tw-text-white tw-mb-4 tw-inline-block">
            Ongeldig ondernemingsnummer
          </span>
          <p class="tw-italic">* Het ondernemingsnummer werd gecheckt via de Europese VIES-databank. Het kan zijn dat dit ondernemingsnummer alsnog correct is, maar niet herkend wordt door het VIES.</p>
        </template>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { getContactVies } from '@/services/contacts'
import { errorModal } from '@/modalMessages'

export default {
  name: 'ContactVatViesChecker',
  props: {
    contact: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      viesData: null,
      viesDataLoading: false
    }
  },
  methods: {
    async fetchViesDetails () {
      try {
        this.viesDataLoading = true
        const response = await getContactVies(this.contact.id)
        this.viesData = response.data
        this.$refs.viesModal.show()
        return response
      } catch (error) {
        errorModal('Er ging iets mis bij het laden van de details van het ondernemingsnummer')
      } finally {
        this.viesDataLoading = false
      }
    }
  }
}
</script>

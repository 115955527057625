// Basisregisters Vlaanderen API
import { baseRegistersClient } from './axiosClients'

export function getParcelDetails (capaKey) {
  return baseRegistersClient.get(`/v2/percelen/${capaKey}`)
}

export function getBuildingUnits (params) {
  return baseRegistersClient.get('/v2/gebouweenheden', { params })
}

export function getBuildingUnitDetails (buildingUnitId) {
  return baseRegistersClient.get(`/v2/gebouweenheden/${buildingUnitId}`)
}

export function getAddressDetails (addressId) {
  return baseRegistersClient.get(`/v2/adressen/${addressId}`)
}

<template>
  <PanelBox title="Historiek">
    <DataTable
      :headers="HEADERS"
      :infinite-scroll="true"
      :show-result-count="false"
      :infinite-key="identifier"
      v-bind="trail"
      class="tw--m-5 tw-shadow-none"
      @changedOrder="changeOrder"
      @infiniteLoad="infiniteLoad"
    >
      <template #item-created_on="{ item }">
        <span>{{ item.created_on | date-day }}</span>
      </template>
    </DataTable>
  </PanelBox>
</template>

<script>
import uniqueId from 'lodash/uniqueId'

import PanelBox from '@/components/iam/PanelBox'
import DataTable from '@/components/DataTable'

import { getPropertyAuditTrail } from '@/services/properties'
import { getProjectAuditTrail } from '@/services/projects'

export default {
  name: 'EntityAuditTrail',
  components: {
    DataTable,
    PanelBox
  },
  props: {
    propertyId: {
      type: [String, Number]
    },
    projectId: {
      type: [String, Number]
    }
  },
  constants: {
    HEADERS: [
      { text: 'Datum', value: 'created_on', orderable: true, orderByKey: 'id' },
      { text: 'Type', value: 'name', orderable: true, orderByKey: 'event__name' },
      { text: 'Waarde', value: 'representation', orderable: true, orderByKey: 'value' },
      { text: 'Medewerker', value: 'collaborator', orderable: true, orderByKey: 'collaborator_name' }
    ]
  },
  data () {
    return {
      trail: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      identifier: uniqueId('audit_trail_'),
      tableParams: {}
    }
  },
  methods: {
    changeOrder (data) {
      this.trail = {
        count: null,
        next: null,
        previous: null,
        results: []
      }
      this.tableParams = data
      this.identifier = uniqueId('refreshed_audit_trail_')
    },
    async loadTrail (payload) {
      try {
        const response = this.propertyId
          ? await getPropertyAuditTrail(payload)
          : await getProjectAuditTrail(payload)
        return response.data
      } catch (error) {
        console.error(error)
      }
    },
    async infiniteLoad ($infinite, next) {
      try {
        const payload = {
          ...this.tableParams,
          ...this.propertyId
            ? { propertyId: this.propertyId }
            : { projectId: this.projectId }
        }
        if (next) payload.url = next
        const trail = await this.loadTrail(payload)
        const results = [...this.trail.results, ...trail.results]
        this.trail = { ...trail, results }
        if (results.length) $infinite.loaded()
        if (!trail.next) $infinite.complete()
        return trail
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    }
  }
}
</script>

<template>
  <div v-if="activeStickers.length" class="tw-mb-4">
    <div
      v-for="sticker in activeStickers"
      :key="sticker.id"
      data-sticker-type="active"
      class="tw-px-2 tw-py-1 tw-mr-1 tw-rounded tw-inline-block"
      :style="`background-color: ${sticker && sticker.sticker.color};`"
    >
      <span data-sticker-title class="tw-text-white tw-font-semibold">
        {{ sticker && sticker.sticker.name }}
      </span>
    </div>
  </div>
</template>

<script>
import compareAsc from 'date-fns/compareAsc'

export default {
  name: 'Stickers',
  props: {
    stickers: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    activeStickers () {
      return this.stickers.filter(sticker => {
        const today = new Date()
        const endDate = new Date(sticker.end_date)
        // We set hh:mm:ss:ms to 00:00:00:00 to compare absolute dates i.e. yyyy/mm/dd
        // milliseconds are important and can make or break the comparison
        today.setHours(0, 0, 0, 0)
        endDate.setHours(0, 0, 0, 0)
        return sticker.end_date ? (compareAsc(endDate, today) >= 0) : true
      })
    }
  }
}
</script>

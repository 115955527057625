<template>
  <BaseModal
    ref="modal"
    title="Medewerkerwijziging"
    max-width="tw-max-w-md"
  >
    <FormulateForm
      name="CollaboratorChangeAndTransferConfirmation"
      @submit="submit"
    >
      <p>Duid hieronder bijkomend aan welke acties ondernomen moeten worden. Klik op "Bevestigen" zonder acties aan te duiden indien je geen leads of entiteiten wilt overzetten.</p>
      <p>Wat wil je overzetten naar {{ collaborator ? collaborator.display_name : '' }} (Dit gebeurt in de achtergrond)?</p>
      <FormulateInput
        type="checkbox"
        name="transfer_entities"
        label="Entiteiten van het project"
        label-class="tw-text-xs tw-mb-0.5"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="checkbox"
        name="transfer_matches"
        label="Leads voor het project en entiteiten"
        label-class="tw-text-xs tw-mb-0.5"
        outer-class="tw-m-0"
      />
      <div class="tw-flex tw-flex-row tw-space-x-4">
        <FormulateInput
          type="submit"
          :input-class="['tw-w-full']"
          :outer-class="['tw-w-full md:tw-w-auto']"
        >
          <i
            :class="[
              'fas tw-mr-2', 'fa-check'
            ]"
          />
          Bevestigen
        </FormulateInput>
      </div>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { errorModal } from '@/modalMessages'
import { updateProjectCollaborator } from '@/services/projects'

export default {
  props: {
    projectId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      collaborator: null
    }
  },
  methods: {
    show (collaborator) {
      this.collaborator = collaborator
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    async submit (data) {
      const { transfer_entities, transfer_matches } = data
      try {
        await updateProjectCollaborator(
          this.projectId,
          this.collaborator.id,
          {
            primary: true,
            transfer_matches,
            transfer_entities
          }
        )
        this.$emit('update')
        this.hide()
      } catch {
        errorModal('Medewerker kan niet als primaire medewerker worden ingesteld')
        this.hide()
      }
    }
  }
}
</script>

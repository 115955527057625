var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseModal',{ref:"modal",attrs:{"title":"Geografische informatie","content-padding":"tw-p-4","max-width":"tw-max-w-[85%]"},on:{"hide":function($event){return _vm.$emit('hide')}}},[_c('EntityMap',{ref:"map",attrs:{"latitude":_vm.latitude,"longitude":_vm.longitude,"parcelString":_vm.parcelString}}),_c('div',{staticClass:"tw-float-right"},[_c('FormulateInput',{attrs:{"type":"button","title":"Opslaan"},on:{"click":_vm.showBuildingUnits}},[_c('i',{staticClass:"fa tw-mr-1 fa-arrow-right"}),_vm._v(" Volgende ")])],1)],1),_c('BaseModal',{ref:"buildingUnits",attrs:{"title":"Gebouweenheden","content-padding":"tw-p-4","max-width":"!tw-overflow-visible tw-max-w-xl"},on:{"hide":_vm.focus}},[(_vm.progressBaseRegistersDataLoad !== 100)?_c('div',[_c('div',{staticClass:"tw-w-full tw-bg-gray-200 tw-rounded-full tw-h-2.5",attrs:{"title":((Math.ceil(_vm.progressBaseRegistersDataLoad)) + "%")}},[_c('div',{staticClass:"tw-bg-success tw-h-2.5 tw-rounded-full",style:(("width: " + _vm.progressBaseRegistersDataLoad + "%"))})])]):_c('FormulateForm',{attrs:{"name":"baseRegistersData"},on:{"submit":_vm.save},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"type":"group","name":"parcels","debounce":"","repeatable":true,"error-class":['tw-text-right'],"outer-class":"tw-mb-1.5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var index = ref.index;
var model = ref.model;
return [_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(model[index] && model[index].label)?_c('div',{staticClass:"tw-flex tw-flex-row tw-gap-2 tw-justify-between tw-py-1.5 tw-items-center"},[_c('div',{staticClass:"tw-w-1/3"},[_c('label',{staticClass:"formulate-label"},[_vm._v(" Perceel ")]),_c('span',{staticClass:"tw-block tw-break-words tw-leading-tight"},[_vm._v(" "+_vm._s(model[index].label)+" ")])]),(model[index].building_units && model[index].building_units.length)?_c('FormulateInput',{attrs:{"type":"multiselect","name":"building_unit_ids","placeholder":"Selecteer gebouweenheden","label":"Gebouweenheden","options":model[index].building_units,"validation":"bail|required|min:1,length","input-class":['tw-h-8 tw-text-sm'],"outer-class":"tw-my-0 tw-flex-grow"}}):_c('p',{staticClass:"tw-my-0 tw-flex-grow"},[_vm._v(" Dit perceel heeft geen gebouweenheden ")])],1):_vm._e()])]}},{key:"addmore",fn:function(){return undefined},proxy:true},{key:"remove",fn:function(){return undefined},proxy:true}],null,true)}),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"outer-class":['tw-float-right']}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]}),_vm._v(" Opslaan ")])]}}]),model:{value:(_vm.baseRegistersData),callback:function ($$v) {_vm.baseRegistersData=$$v},expression:"baseRegistersData"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }